import { CheckCircleOutlined, DeleteOutlined, EditOutlined, FilePdfOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Drawer, Form, Input, message, notification, Popconfirm, Row, Select, Space, Spin, Table, Tag } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import CustomPagination from '../../components/CustomPagination';
import InsideError from '../../components/InsideError';
import LayoutFull from '../../components/LayoutFull';
import api from '../../services/api.service';

type FormFilter = {
    date_start: string;
    date_end: string;
    plot_id: string;
    product_type_id: string;
    date_type: string;
}

export function HarvestMoviments() {

    const [dataTable, setDataTable] = useState([])
    const [filters, setFilters] = useState<FormFilter>({} as FormFilter)
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [showDrawer, setShowDrawer] = useState(false)
    const [drawerTitle, setDrawerTitle] = useState('')
    const [productTypeList, setProductTypeList] = useState<any[]>([])
    const [plotList, setPlotList] = useState<any[]>([])
    const [productList, setProductList] = useState<any[]>([])
    const [loadingButtonPdf, setLoadingButtonPdf] = useState<boolean>(false)

    const [form] = Form.useForm()
    const { Option } = Select

    const columns: any[] = [
        {
            title: 'UP',
            key: 'plot',
            dataIndex: 'plot',
            ellipsis: 'enable',
            render: (text: string, record: any) => {
                return (
                    <div>
                        <strong>{text}</strong><br />
                        {record.user && <Tag color="red">{record.user}</Tag>}
                    </div>
                )
            }
        },
        {
            title: 'Cultura',
            key: 'product_type',
            dataIndex: 'product_type',
            ellipsis: 'enable',
            render: (text: string) => {
                return <strong>{text}</strong>
            }
        },
        {
            title: 'Corte em',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text: any) => {
                return (
                    <div>
                        <strong>Semana: {moment(text).isoWeeks()}</strong>
                        <br />
                        {text && moment(text).utc().format('DD/MM/YYYY')}
                    </div>
                )
            },
        },
        {
            title: 'Prazo',
            key: 'duration',
            dataIndex: 'duration',
            render: (text: any) => {
                return `${text} dias`
            }
        },
        {
            title: 'Data Previsão',
            key: 'provisioned_at',
            dataIndex: 'provisioned_at',
            render: (text: any) => {
                return `${moment(text).utc().format('DD/MM/YYYY')}`
            }
        },
        {
            title: 'Colhido em',
            key: 'harvest_at',
            dataIndex: 'harvest_at',
            render: (text: any) => {
                return text && moment(text).utc().format('DD/MM/YYYY')
            }
        },
        {
            title: 'Perdidos',
            key: 'is_lost',
            dataIndex: 'is_lost',
            render: (text: any) => {
                return text
            }
        },
        {
            title: 'Colhidos',
            key: 'harvesteds',
            dataIndex: 'harvesteds',
            render: (text: any) => {
                return text
            }
        },
        {
            title: 'Ações',
            key: 'actions',
            align: 'center',
            render: (text: any, record: any) => (
                <Space size="small">
                    <Button type="link" onClick={() => hidrateFormWithValues(record)}>
                        <EditOutlined />{' '}Editar
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Deseja realmente excluir esse item?"
                        onConfirm={() => remove(record.id)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="link" style={{ color: 'red' }} >
                            <DeleteOutlined />{' '}Excluir
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    const getData = useCallback(async () => {
        try {
            const params = { ...filters, ...{ page: currentPage }, ...{ step: 'HARVEST' } }
            setLoading(true)
            const { data } = await api.get('harvest-forecast', { params })
            setDataTable(data)
        } catch (err: any) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }, [currentPage, filters])

    const handlerFilter = async (values: any) => {
        setCurrentPage(1)
        setFilters({
            date_start: values.date_start && moment(values.date_start).format('YYYY-MM-DD'),
            date_end: values.date_end && moment(values.date_end).format('YYYY-MM-DD'),
            date_type: values.date_type,
            plot_id: values.plot_id,
            product_type_id: values.product_type_id,
        });
    }

    const openDrawer = () => {
        setErrors([])
        form.resetFields()
        setShowDrawer(true)
        setDrawerTitle('Novo cadastro')
    }

    const searchProductType = async (search: string) => {
        try {
            setLoading(true)
            const { data } = await api.get(`product-type`, { params: { filters: { name: search } } })
            setProductTypeList(data)
        } catch (err: any) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    const searchPlot = async (search: string) => {
        try {
            setLoading(true)
            const { data } = await api.get(`plot`, { params: { filters: { name: search } } })
            setPlotList(data)
        } catch (err: any) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    const searchProduct = async (search: string) => {
        try {
            setLoading(true)
            const { data } = await api.get(`product-type`, { params: { filters: { name: search } } })
            setProductList(data)
        } catch (err: any) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    const hidrateFormWithValues = useCallback((values: any) => {
        values.created_at = moment(values.created_at);
        values.provisioned_at = moment(values.provisioned_at);
        values.harvest_at = moment(values.harvest_at);
        form.setFieldsValue(values)
        setShowDrawer(true)
        setDrawerTitle('Editar cadastro')
        setErrors([])
    }, [form])

    const saveOrCreate = async (values: any) => {
        if (values.id) {
            try {
                setLoading(true)
                await api.put(`harvest-forecast/${values.id}`, values)
                setShowDrawer(false);
                getData()
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro atualizado com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });
            } catch (err: any) {
                setErrors(err?.response?.data?.errors)
            } finally {
                setLoading(false)
            }
        } else {
            try {
                setLoading(true)
                await api.post(`harvest-forecast`, values)
                setShowDrawer(false);
                getData()
                notification.open({
                    message: 'Sucesso',
                    description: 'Registro incluido com sucesso',
                    icon: <CheckCircleOutlined style={{ color: 'green' }} />
                });
            } catch (err: any) {
                setErrors(err?.response?.data?.errors)
            } finally {
                setLoading(false)
            }
        }
    }

    const remove = useCallback(async (id: string) => {
        try {
            await api.delete(`harvest-forecast/${id}`)
            message.success("Registro excluido com sucesso")
            getData()
        } catch (err: any) {
            message.error(err.message)
        }
    }, [getData])

    const handlerDownloadPdf = async () => {
        try {
            setLoadingButtonPdf(true)
            const { data } = await api.get('harvest-forecast/pdf', { params: filters, responseType: 'blob' })
            const file = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(file);
            window.open(url);
        } catch (err: any) {
            message.error(err.message)
        } finally {
            setLoadingButtonPdf(false)
        }
    }

    useEffect(() => {
        getData()
    }, [getData])

    return (
        <LayoutFull headerTitle="Lançamentos de colheita">
            <Form
                initialValues={{ date_type: 'provisioned_at' }}
                onFinish={handlerFilter}
                onFinishFailed={() => { }}
                autoComplete="off"
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item
                            name="plot_id"
                            label="Lote"
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Digite o nome do lote para buscar..."
                                notFoundContent={!plotList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => searchPlot(value)}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                {(plotList || []).map((item: any) => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="product_type_id"
                            label="Variedade Fruta"
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Digite o nome da fruta..."
                                notFoundContent={!productList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => searchProduct(value)}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                {(productList || []).map((item: any) => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="date_start"
                                    label="Data inicial"
                                >
                                    <DatePicker format='DD/MM/YYYY' placeholder={'Data Inicial'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="date_end"
                                    label="Data Final"
                                >
                                    <DatePicker format='DD/MM/YYYY' placeholder={'Data Final'} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name="date_type"
                            label="Tipo de data"
                        >
                            <Select
                                labelInValue={false}
                                placeholder="Selecione"
                                notFoundContent={!plotList.length ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                filterOption={false}
                                onSearch={(value) => { }}
                                style={{ width: '100%' }}
                                showSearch
                                allowClear
                            >
                                <Option key={2} value="provisioned_at">Data de previsão</Option>
                                <Option key={1} value="harvest_at" selected>Data de colheita</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ marginTop: 30 }}>
                            Buscar
                        </Button>
                        <Button type="primary" onClick={openDrawer} style={{ marginLeft: 8, background: '#499c70', borderColor: 'transparent' }}>
                            <PlusOutlined />{' '}Novo
                        </Button>
                        <Button
                            onClick={() => handlerDownloadPdf()}
                            htmlType="button"
                            icon={<FilePdfOutlined />}
                            style={{ marginTop: 30, backgroundColor: '#3d85c6', color: '#fff', marginLeft: 8 }}
                            loading={loadingButtonPdf}
                        >
                            Gerar PDF
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Table
                columns={columns}
                dataSource={dataTable}
                rowKey={() => Math.random()}
                loading={loading}
                pagination={false}
                tableLayout="auto"
            />
            <CustomPagination
                onPrevious={() => setCurrentPage(currentPage - 1)}
                onNext={() => setCurrentPage(currentPage + 1)}
                currentPage={currentPage}
                dataTableLength={Boolean(dataTable.length)}
            />
            <Drawer
                title={drawerTitle}
                width={720}
                onClose={() => setShowDrawer(false)}
                maskClosable={false}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={saveOrCreate}
                    initialValues={{
                        id: ''
                    }}
                >
                    <Row gutter={16}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly name="id" />
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                name="plot_id"
                                label="UP - Unidade de Produção"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    labelInValue={false}
                                    placeholder="Digite o nome da UP para buscar..."
                                    notFoundContent={!plotList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                    filterOption={false}
                                    onSearch={(value) => searchPlot(value)}
                                    style={{ width: '100%' }}
                                    showSearch
                                    allowClear
                                >
                                    {(plotList || []).map((item: any) => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="product_type_id"
                                label="Produto"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    labelInValue={false}
                                    placeholder="Digite o nome do produto para buscar..."
                                    notFoundContent={!productTypeList ? <Spin size="small" /> : <div>Nenhum dado encontrado</div>}
                                    filterOption={false}
                                    onSearch={(value) => searchProductType(value)}
                                    style={{ width: '100%' }}
                                    showSearch
                                    allowClear
                                >
                                    {productTypeList.map((item: any) => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="created_at"
                                label="Data do corte coração"
                                rules={[{ required: true }]}
                            >
                                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="provisioned_at"
                                label="Data previsão colheita"
                                rules={[{ required: true }]}
                            >
                                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="harvest_at"
                                label="Data colheita"
                            >
                                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="duration"
                                label="Duração (dias)"
                                rules={[{ required: true }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="is_lost"
                                label="Cachos perdidos"
                                rules={[{ required: true }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="harvesteds"
                                label="Cachos colhidos"
                                rules={[{ required: true }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="step"
                                label="Etapa"
                                rules={[{ required: true }]}
                            >
                                <Select >
                                    <Option value="HARVEST">Colheita</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Button onClick={() => setShowDrawer(false)} style={{ marginRight: 8 }} block htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button type="primary" htmlType="submit" block>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {Boolean(errors.length) && <InsideError errors={errors} />}
            </Drawer>
        </LayoutFull>
    )
}